import { Modal } from "antd";
// import printAnalieasyImg from "../../assets/images/print-analieasy.png"
import logoAnalieasyImg from "../../assets/images/logo-analieasy.png"
import logoTopConsulta from "../../assets/images/remove_topconsulta.png"
// import styled, { keyframes } from "styled-components";

const DialogNotice = ({ isOpen, onClose }) => {

  // const openPageWhatsMarcelo = () => {
  //   // numero do marcelo
  //   window.open(
  //     `https://api.whatsapp.com/send?phone=5511988376915&text=Ol%C3%A1,%20tenho%20top%20consulta%20e%20gostaria%20de%20utilizar%20o%20Analieasy`,
  //     "_blank"
  //   );
  // }

  // const fadeAnimation = keyframes`
  //   0%  { opacity: 1;}
  //   50% { opacity: 0.4;}
  //   100% { opacity: 0.9;}
  // `;

  // const FadeButton = styled.button`
  //   animation: ${fadeAnimation} 2s linear infinite;
  // `;

  return (
    <>
      <Modal
        title={"Atualize agora seu sistema"}
        open={isOpen}
        onCancel={onClose}
        footer={null}
        style={{minWidth: '1000px'}}
      >
        <div className="text-center">
    
          <div className="d-flex align-items-center justify-content-center gap-3" >
            <img src={logoTopConsulta} alt="consultatop-logo" className="mt-3" style={{width: '180px'}} />
            <i className="bi bi-arrow-right mt-2 text-danger" style={{ fontSize: '38px' }} ></i>
            <img src={logoAnalieasyImg} alt="analieasy-logo" className="mt-3 ml-2" style={{width: '170px'}} />
          </div>

          <h2 className="mt-5 text-danger" style={{fontWeight: 'bold'}}>
            <div className="d-flex justify-content-center gap-3">
              <i className="bi bi-exclamation-triangle ml-2 text-danger" style={{ fontSize: '30px' }} ></i>
              ATENÇÃO
              <i className="bi bi-exclamation-triangle ml-2 text-danger" style={{ fontSize: '30px' }} ></i>
            </div>
          </h2>

          <h4 className="mt-1 text-danger" style={{fontWeight: 'bold'}}>
            O sistema Top Consulta será desativado
          </h4>
          {/* <img src={printAnalieasyImg} alt="Profile" className="mt-1" style={{width: '660px'}} /> */}
        </div>

        <div className="mt-4">

          <h5>
            O sistema Top Consulta será <b>DESCONTINUADO</b>, deixando de receber qualquer atualização, suporte ou manutenção.
            <br/><br/>Isso significa que o acesso ao sistema será encerrado, sendo necessário migrarmos seu acesso para o novo sistema Analieasy.
            <span className="" style={{ fontWeight: 'bold' }}><br/><br/>Entre em contato conosco para migrarmos sua operação para o novo sistema</span>
            <br/><br/>📲 Fale conosco agora e garanta sua continuidade!
          </h5>

          {/* <h6>
            Informamos que o sistema o sistema Top Consulta será descontinuado em breve e não receberá mais atualizações ou suporte.
          </h6>

          <div className="mt-2">
            Para continuar acessando serviços mais ágeis, modernos e completos, convidamos você a conhecer o Analieasy, nossa nova plataforma que oferece uma experiência aprimorada e mais eficiente, sem contar inúmeras outras vantagens para você!
          </div>

          <div className="mt-2" style={{fontWeight: 'bold'}}>
            Não fique sem acesso! Entre em contato para migrar e garantir a continuidade do seu serviço.
          </div> */}

          {/* <div className="mt-5 text-center">
            <FadeButton
              className="btn btn-lg btn-success"
              title="Falar agora com o consultor Analieasy"
              onClick={openPageWhatsMarcelo}
            >
              <i className="text-white bi bi-chevron-double-right"></i>
              <span style={{marginLeft: '4px', fontWeight: 'bold'}}>
                Quero atualizar meu sistema agora
              </span>
            </FadeButton>
          </div> */}

        </div>
      </Modal>
    </>
  );
};

export default DialogNotice;
