import moment from "moment";

export function completeCpf(value) {
  if (!value) return value;
  let data = value.replace(/[^0-9]/g, "");
  return `00000000000${data}`.slice(-11);
}

export function completeBeneficio(value) {
  if (!value) return value;
  let data = value.replace(/[^0-9]/g, "");
  return `0000000000${data}`.slice(-10);
}

export function formatCPF(number) {
  if (!number) return number
  const stringValue = String(number);

  if (!/^\d{11}$/.test(stringValue)) {
    return stringValue;
  }

  return stringValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function formatRG(rgString) {
  if (!rgString) return rgString;
  const parte1 = rgString.slice(0, 2);
  const parte2 = rgString.slice(2, 5);
  const parte3 = rgString.slice(5);
  const rgFormatado = `${parte1}.${parte2}.${parte3}`;

  return rgFormatado;
}

export function formatBeneficio(number) {
  if (!number || isNaN(number)) {
    return number;
  }
  return number.replace(/(\d{3})(\d{3})(\d{3})(\d{1})/, "$1.$2.$3-$4");
}

export function formatarValorEmDinheiro(valor) {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  const valorEmReais = formatter.format(valor);

  return valorEmReais;
}

export function formatarCNPJ(cnpj) {
  if (!cnpj) return cnpj;
  cnpj = cnpj.replace(/\D/g, "");

  return cnpj.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    "$1.$2.$3/$4-$5"
  );
}

export function formatTypeConsult(value) {
  let result = "";
  switch (value) {
    case "saldo_consulta_geral_cnpj":
      result = "CONSULTA GERAL (PJ)";
      break;
    case "saldo_in100":
      result = "CONSULTA IN100";
      break;
    case "saldo_extrato_online":
      result = "CONSULTA ONLINE";
      break;
    case "saldo_extrato_off":
      result = "CONSULTA OFFLINE";
      break;
    case "saldo_siape":
      result = "CONSULTA SIAPE";
      break;
    case "saldo_fgts":
      result = "CONSULTA FGTS";
      break;
    case "saldo_phone":
      result = "CONSULTA TELEFONE";
      break;
    case "saldo_consulta_geral":
      result = "CONSULTA GERAL (PF)";
      break;
    case "saldo_extrato_offline_cpf":
      result = "CONSULTA OFFLINE (CPF)";
      break;
    case "saldo_in100_plus":
      result = "CONSULTA IN100 PLUS";
      break;
    case "saldo_phone_cel_obg":
      result = "FONE CEL OBG WHATS";
      break;
    case "saldo_cel_obg_no_whats":
      result = "FONE CEL OBG";
      break;
    case "saldo_extrato_serasa_cpf":
      result = "SERASA CONSUMIDOR";
      break;
    case "saldo_extrato_serasa_cnpj":
      result = "SERASA CNPJ";
      break;
  }
  return result;
}

export function formatDateChangeHifenToBar(value) {
  if (!value) return value;
  return value.replace(/-/g, "/");
}

export function formatDatePadrao(value) {
  if (!value) return value;
  const d = value.split("-");
  const dataR = `${d[2]}-${d[1]}-${d[0]}`;
  return dataR;
}

export function formatDateNew(value) {
  if (typeof value === "string") {
    console.log('..............', value)
    const DATE_FORMATS = [
      "YYYY-MM-DD HH:mm:ss",
      "YYYYMMDD",
      "YYYY-MM-DD",
      "YYYYMM",
      "YYYY-MM",
      "DDMMYYYY",
      "YYYYMM",
      "DD/MM/YYYY",
      "MMYYYY",
      "MM/YYYY",
      "YYYY-MM-DDTHH:mm:ss.SSSZ",
      "YYYY-MM-DD",
      "DD-MM-YYYY",
      "YY-MM-DDDD",
    ];
    const formatoDeSaida = value.length <= 7 ? 'MM/YYYY' : 'DD/MM/YYYY';

    return moment(value, DATE_FORMATS, true).format(formatoDeSaida);
  }
  return value
}

export function formatDate(value) {
  if (!value) return value;
  console.log(value)
  const DATE_FORMATS = [
    "YYYY-MM-DD HH:mm:ss",
    "YYYYMMDD",
    "YYYY-MM-DD",
    "YYYYMM",
    "YYYY-MM",
    "DDMMYYYY",
    "DD/MM/YYYY",
    "MMYYYY",
    "MM/YYYY",
    "YYYY-MM-DDT00:00:00.000Z",
    "YYYY-MM-DDT03:00:00.000Z",
    "YYYY-MM-DDT00:00:00.00",
    "YYYY-MM-DD",
    "DD-MM-YYYY",
    "YY-MM-DDDD",
    "YYYY-MM-DDTHH:mm:ss",
  ];
  const formatoDeSaida = value.length <= 7 ? 'MM/YYYY' : 'DD/MM/YYYY';
  const momentDate = moment(value, DATE_FORMATS, true);
  return momentDate.format(formatoDeSaida);
}


// format YYYYMMDD
export function formatDateYYYYMMDD(dataString) {
  if (!dataString) return dataString;
  const ano = dataString.slice(0, 4);
  const mes = dataString.slice(4, 6);
  const dia = dataString.slice(6, 8);
  const dataFormatada = `${dia}/${mes}/${ano}`;

  return dataFormatada;
}

export function formatDateReturnMonthAndYear(value) {
  if (!value) return value;
  if (typeof value === "string") value = new Date(value);
  const DATE_FORMATS = [
    "YYYY-MM-DD HH:mm:ss",
    "YYYYMMDD",
    "YYYY-MM-DD",
    "YYYYMM",
    "YYYY-MM",
    "DDMMYYYY",
    "DD/MM/YYYY",
    "MMYYYY",
    "MM/YYYY",
    "YYYY-MM-DDTHH:mm:ss.SSSZ",
    "YYYY-MM-DD",
    "DD-MM-YYYY",
    "YY-MM-DDDD",
  ];
  const date = moment.utc(value, DATE_FORMATS, true);
  if (date.isValid()) return date.startOf("day").format("MM/YYYY");
  return null;
}

export function formatName(name) {
  if (!name) return name;
  return name
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

export function formatUndefined(data) {
  return data === "" || data === undefined ? "Indefinido" : data;
}

export function formatarCEP(cepString) {
  if (!cepString) return cepString;
  cepString = cepString.replace(/\D/g, "");
  cepString = cepString.replace(/(\d{5})(\d{3})/, "$1-$2");
  return cepString;
}

export function formatarNumeroTelefone(numero) {
  if (!numero) return numero

  const numerosApenas = numero.replace(/\D/g, "");

  // Verifica se é um número de celular ou fixo
  if (numerosApenas.length === 11) {
    // Formato para número de celular
    const ddd = numerosApenas.substring(0, 2);
    const parte1 = numerosApenas.substring(2, 7);
    const parte2 = numerosApenas.substring(7, 11);
    return `(${ddd}) ${parte1}-${parte2}`;
  } else if (numerosApenas.length === 10) {
    // Formato para número fixo
    const ddd = numerosApenas.substring(0, 2);
    const parte1 = numerosApenas.substring(2, 6);
    const parte2 = numerosApenas.substring(6, 10);
    return `(${ddd}) ${parte1}-${parte2}`;
  } else {
    // Retorna o número original se não for possível formatar
    return numero;
  }
}
